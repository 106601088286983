/* DayPicker styles */

.DayPicker {
  @apply inline-block text-sm;
}

.DayPicker-wrapper {
  @apply relative flex-col flex select-none pb-1;
}

.DayPicker-Months {
  @apply flex flex-wrap justify-center;
}

.DayPicker-Month {
  @apply table select-none border-collapse mt-1 mx-1;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  @apply cursor-pointer text-gray-700 h-3 w-3 mt-1 inline-block left-auto right-1 top-1 absolute bg-center bg-no-repeat bg-contain;
}

.DayPicker-NavButton:hover {
  @apply opacity-80;
}

.DayPicker-NavButton--prev {
  @apply mr-8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
  @apply mr-2;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  @apply hidden;
}

.DayPicker-Caption {
  @apply table-caption mb-1 py-0 px-2 text-left;
}

.DayPicker-Caption > div {
  @apply text-base font-medium;
}

.DayPicker-Weekdays {
  @apply table-header-group mt-1;
}

.DayPicker-WeekdaysRow {
  @apply table-row;
}

.DayPicker-Weekday {
  @apply table-cell p-1 text-gray-500 text-center text-sm;
}

.DayPicker-Weekday abbr[title] {
  @apply border-b-0 no-underline;
}

.DayPicker-Body {
  @apply table-row-group;
}

.DayPicker-Week {
  @apply table-row;
}

.DayPicker-Day {
  @apply rounded-full table-cell focus:outline-none focus:ring-primary-400 focus:ring-1 focus:ring-offset-0 w-9 h-9 align-middle text-center cursor-pointer leading-none;
}

.DayPicker-WeekNumber {
  @apply table-cell p-2 w-1 border-r-2 border-gray-200 text-gray-600 align-middle text-right text-xs cursor-default;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  @apply cursor-default;
}

.DayPicker-Footer {
  @apply flex pr-4 p-1 justify-end;
}

.DayPicker-TodayButton {
  @apply text-primary-400 text-xs;
}

/* Default modifiers */

.today {
  @apply text-primary-400 font-semibold;
}

.outside {
  @apply text-gray-600 cursor-default;
}

.disabled {
  @apply text-white cursor-default;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  @apply bg-white;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  @apply text-white;
}

.selected {
  @apply bg-primary-400 text-white relative focus:ring-white;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.disabled):not(.selected):not(.outside):hover {
  @apply bg-primary-100;
}
