.loader {
  position: relative;
  height: 1em;
  width: 1em;
  border-width: 0.1em;
  border-top-color: rgba(255, 255, 255, 0.2) !important;
  border-right-color: rgba(255, 255, 255, 0.2) !important;
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.color-primary {
  border-left-color: theme("colors.primary.400");
}

.color-red {
  border-left-color: theme("colors.red.400");
}

.color-white {
  border-left-color: theme("colors.white");
}

.color-gray {
  border-left-color: theme("colors.gray.900");
}

.color-light-gray {
  border-left-color: theme("colors.gray.400");
}

.size-sm {
  font-size: theme("fontSize.sm");
}

.size-md {
  font-size: theme("fontSize.base");
}

.size-lg {
  font-size: theme("fontSize.2xl");
}

.size-xl {
  font-size: theme("fontSize.5xl");
}
